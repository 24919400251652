import React, { useState, useEffect, useCallback } from "react";
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Paper,
  Typography,
  Box,
  Snackbar,
  Alert,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem, // Import Alert
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import axios from "axios";

const Cash_Tab = () => {
  const [dataRows, setDataRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [seletedInputValue, setSelectedInputValue] = useState("");
  // Removed: const [error, setError] = useState(''); // Using Snackbar for errors
  const theme = useTheme();
  // Snackbar state variables
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // 'success' | 'error' | 'warning' | 'info'
  const [selectedCategory, setSelectedCategory] = useState("");
  

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  // Utility function to extract error messages
  const getErrorMessage = (error) => {
    if (error.response) {
      if (typeof error.response.data === "string") {
        return error.response.data;
      } else if (error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
    }
    return "An unexpected error occurred.";
  };

  // Function to fetch suggestions from the API
  const fetchSuggestions = useCallback(async (query) => {
    if (query.length === 0) {
      setSymbolOptions([]);
      return;
    }

    try {
      const response = await axios.get(
        `https://api.arasu.online/api/symbol/Search`,
        {
          params: {
            query: query,
            limit: 25,
          },
        }
      );
      setSymbolOptions(response.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, []);

  // useEffect to call fetchSuggestions when inputValue changes
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions(inputValue);
    }, 300); // Debounce the API call by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, fetchSuggestions]);

  // Fetch all cash data on component mount
  useEffect(() => {
    const fetchAllCashData = async () => {
      try {
        const response = await axios.get(
          "https://api.arasu.online/api/Subscribe/CashData"
        );
        setDataRows(response.data);

        // Trigger success Snackbar
        setSnackbarMessage("Cash data fetched successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } catch (error) {
        console.error("Error fetching cash data:", error);
        const message = getErrorMessage(error);
        setSnackbarMessage(message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    };

    fetchAllCashData();
  }, []);

  const handleAutocompleteChange = async (event, newValue) => {
    setSelectedValue(newValue);

    if (newValue) {
      const newSymbol = newValue.symTicker;

      // Check if the symbol is already in dataRows
      const exists = dataRows.some((row) => row.symbol === newSymbol);
      if (!exists) {
        try {
          // Save the new symbol to the backend
          const response = await axios.post(
            "https://api.arasu.online/api/Subscribe/CashData",
            {
              symbol: "NSE:" + newSymbol,
            }
          );

          if (response.status === 200 || response.status === 201) {
            // Fetch the updated list of cash data
            const updatedResponse = await axios.get(
              "https://api.arasu.online/api/Subscribe/CashData"
            );
            setDataRows(updatedResponse.data);

            // Trigger success Snackbar
            setSnackbarMessage("Symbol added successfully.");
            setSnackbarSeverity("success");
            setSnackbarOpen(true);

            setSelectedInputValue("");
          } else {
            const message =
              response.data?.message || "Failed to save the new symbol.";
            setSnackbarMessage(message);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            setSelectedInputValue("");
          }
        } catch (error) {
          console.error("Error saving new symbol:", error);
          const message = getErrorMessage(error);
          setSnackbarMessage(message);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          setSelectedInputValue("");
        }
      } else {
        // Symbol already exists, trigger error Snackbar
        setSnackbarMessage("Symbol already exists in the list.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setSelectedInputValue("");
      }
    }

    // Clear selectedRows when the data changes
    setSelectedRows([]);
  };

  const handleDelete = async (id) => {
    try {
      // Delete the symbol from the backend
      const response = await axios.delete(
        `https://api.arasu.online/api/Subscribe/CashData/${id}`
      );
      if (response.status === 200) {
        // Update the dataRows state
        setDataRows((prevRows) => prevRows.filter((row) => row.id !== id));
        setSelectedRows((prevSelected) =>
          prevSelected.filter((rowId) => rowId !== id)
        );
        // If the deleted symbol is the selectedValue, clear it
        if (selectedValue && selectedValue.id === id) {
          setSelectedValue(null);
        }

        // Trigger success Snackbar
        setSnackbarMessage("Symbol deleted successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      } else {
        const message =
          response.data?.message || "Failed to delete the symbol.";
        setSnackbarMessage(message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Error deleting symbol:", error);
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = dataRows.map((n) => n.id);
      setSelectedRows(newSelecteds);
      return;
    }
    setSelectedRows([]);
  };

  const handleCheckboxClick = (event, id) => {
    event.stopPropagation();
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }

    setSelectedRows(newSelected);
  };

  const handleDeleteSelected = async () => {
    try {
      // Delete selected symbols from the backend
      for (const id of selectedRows) {
        await axios.delete(
          `https://api.arasu.online/api/Subscribe/CashData/${id}`
        );
      }
      // Update the dataRows state
      setDataRows((prevRows) =>
        prevRows.filter((row) => !selectedRows.includes(row.id))
      );
      setSelectedRows([]);
      // If the selectedValue is among the deleted rows, clear it
      if (selectedValue && selectedRows.includes(selectedValue.id)) {
        setSelectedValue(null);
      }

      // Trigger success Snackbar
      setSnackbarMessage("Selected symbols deleted successfully.");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error deleting selected symbols:", error);
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const isSelected = (id) => selectedRows.indexOf(id) !== -1;

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    console.log(event.target.value);
    const Index = event.target.value;
    fetchSymbols(Index);
  };

  const fetchSymbols = async (tabIndex) => {
    // Lists are 1-indexed
   
    try {
      const response = await axios.get(
        `https://api.arasu.online/api/BulkSymbols?listId=${tabIndex}`
      );

      console.log(response.data);
      const symbollist = response.data; // Array of symbols

      // Add each symbol to the watchlist
      await Promise.all(symbollist.map((symbol) => AddBulkSymbol(symbol)));

    } catch (error) {
      console.error("Error fetching symbols:", error);
      // setSnackbar({ open: true, message: 'Failed to fetch symbols.', severity: 'error' });
    } finally {
      
    }
  };

  const AddBulkSymbol = async (Symbol) =>{

    try {
      // Save the new symbol to the backend
      const response = await axios.post(
        "https://api.arasu.online/api/Subscribe/CashData",
        {
          symbol: Symbol.name,
        }
      );

      if (response.status === 200 || response.status === 201) {
        // Fetch the updated list of cash data
        const updatedResponse = await axios.get(
          "https://api.arasu.online/api/Subscribe/CashData"
        );
        setDataRows(updatedResponse.data);

        // Trigger success Snackbar
        setSnackbarMessage("Symbol added successfully.");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);

        setSelectedInputValue("");
      } else {
        const message =
          response.data?.message || "Failed to save the new symbol.";
        setSnackbarMessage(message);
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setSelectedInputValue("");
      }
    } catch (error) {
      console.error("Error saving new symbol:", error);
      const message = getErrorMessage(error);
      setSnackbarMessage(message);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      setSelectedInputValue("");
    }
  }

  return (
    <>
      <Box>
        {/* Snackbar for Toast Messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000} // Duration in milliseconds
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }} // Position of the Snackbar
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Box
          sx={{
            marginBottom: theme.spacing(2),
            display: "flex",
            alignItems: "center",
            gap: (theme) => theme.spacing(2), // Adds space between dropdown and Autocomplete
            flexWrap: "wrap",
          }}
        >
          {/* Autocomplete Component */}
          <Autocomplete
            sx={{ width: "350px" }}
            options={symbolOptions}
            getOptionLabel={(option) => option.symTicker || ""}
            value={selectedValue}
            inputValue={seletedInputValue}
            onChange={handleAutocompleteChange}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
              setSelectedInputValue(newInputValue);
              // Removed: setError(''); // Using Snackbar instead
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Symbol" variant="outlined" />
            )}
          />

          <FormControl sx={{ minWidth: 200 }}>
            <InputLabel id="category-select-label">Category</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={selectedCategory}
              label="Category"
              onChange={handleCategoryChange}
            >
              <MenuItem value="1">Symbol List 1</MenuItem>
              <MenuItem value="2">Symbol List 2</MenuItem>
              <MenuItem value="3">Symbol List 3</MenuItem>
              <MenuItem value="4">Symbol List 4</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleDeleteSelected}
          sx={{ mt: 1 }}
          disabled={selectedRows.length === 0}
        >
          Delete Selected
        </Button>
        {/* Data Table with Delete Button and Multiple Select */}
        {dataRows.length > 0 ? (
          <TableContainer component={Paper} sx={{ mt: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedRows.length > 0 &&
                        selectedRows.length < dataRows.length
                      }
                      checked={
                        dataRows.length > 0 &&
                        selectedRows.length === dataRows.length
                      }
                      onChange={handleSelectAllClick}
                      inputProps={{
                        "aria-label": "select all symbols",
                      }}
                    />
                  </TableCell>
                  <TableCell>ID</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  return (
                    <TableRow
                      key={row.id}
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) =>
                            handleCheckboxClick(event, row.id)
                          }
                          inputProps={{
                            "aria-labelledby": `enhanced-table-checkbox-${index}`,
                          }}
                        />
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row.symbol.replace("NSE:", "")}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleDelete(row.id)}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography sx={{ mt: 2 }}>No data available.</Typography>
        )}
      </Box>
    </>
  );
};

export default Cash_Tab;
