import React from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  TextField,
  Grid,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Responsive width
  maxWidth: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const UserDataEditModel = ({
  open,
  handleClose,
  formData,
  handleChange,
  handleSubmit,
}) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        {/* Modal Header */}
        <Typography variant="h6" component="h2" gutterBottom>
          Edit User Details
        </Typography>

        {/* Grid Container for Form Fields */}
        <Grid container spacing={2}>
          {/* First Row */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="User Id"
              name="UserId"
              variant="outlined"
              value={formData.UserId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Client Id"
              name="ClientId"
              variant="outlined"
              value={formData.ClientId}
              onChange={handleChange}
            />
          </Grid>

          {/* Second Row */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Secret Key"
              name="Secretkey"
              variant="outlined"
              multiline
              value={formData.Secretkey}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Pin"
              name="Pin"
              variant="outlined"
              multiline
              value={formData.Pin}
              onChange={handleChange}
            />
          </Grid>

          {/* Third Row */}
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Redirect Uri"
              name="RedirectUri"
              variant="outlined"
              multiline
              value={formData.RedirectUri}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Refresh Token Url"
              name="RefreshTokenUrl"
              variant="outlined"
              value={formData.RefreshTokenUrl}
              onChange={handleChange}
            />
          </Grid>

          {/* Fourth Row */}
          <Grid item xs={12} >
            <TextField
              fullWidth
              multiline
              label="App Hash Id"
              name="AppHashId"
              variant="outlined"
              value={formData.AppHashId}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              label="Refresh Token"
              name="RefreshToken"
              variant="outlined"
              value={formData.RefreshToken}
              onChange={handleChange}
            />
          </Grid>

          {/* Fifth Row */}
          <Grid item xs={12} >
            <TextField
              fullWidth
              multiline
              label="Access Token"
              name="AccessToken"
              variant="outlined"
              value={formData.AccessToken}
              onChange={handleChange}
            />
          </Grid>

          {/* Buttons Row */}
         
            <Grid item xs={6}>
              <Button
                fullWidth
                sx={{ mt: 2, backgroundColor: "#84cf0e" }}
                variant="contained"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={handleClose}
                sx={{ mt: 2, backgroundColor: "#f0a918" }}
                variant="contained"
              >
                Close
              </Button>
            </Grid>
          </Grid>
        
      </Box>
    </Modal>
  );
};

export default UserDataEditModel;
