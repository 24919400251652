/* eslint-disable react/jsx-pascal-case */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useReducer,
} from "react";
import HomePage from "./HomePage";
import {
  Autocomplete,
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Tooltip,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

/**
 * Helper function to format symbols consistently.
 */
const formatSymbol = (symbol) => {
  return symbol.startsWith("NSE:")
    ? symbol.trim().toUpperCase()
    : `NSE:${symbol.trim().toUpperCase()}`;
};

/**
 * Helper function to format numbers.
 */
const formatNumber = (number) => {
  if (number === undefined || number === null) return "-";
  return new Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
    number
  );
};

/**
 * Table Row Component
 * Renders a single row for Calls and Puts.
 */
const OptionRow = ({ strikePrice, callData, putData, index }) => {
  return (
    <TableRow
      sx={{
        backgroundColor: index % 2 === 0 ? "grey.100" : "white",
        "&:hover": {
          backgroundColor: "action.hover",
        },
      }}
    >
      {/* Call Data */}
      <TableCell sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        <Tooltip title={callData?.Symbol || ""}>
          <span>{callData?.Symbol.replace("NSE:", "") || "-"}</span>
        </Tooltip>
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(callData?.Oi)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(callData?.Volume)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(callData?.Ltpchp)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(callData?.Ltpch)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(callData?.Ltp)}
      </TableCell>

      {/* Strike Price */}
      <TableCell
        align="center"
        sx={{
          fontWeight: "500",
          fontSize: "0.875rem",
          backgroundColor: "primary.light",
        }}
      >
        {strikePrice}
      </TableCell>

      {/* Put Data */}
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(putData?.Ltp)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.865rem", fontWeight: "400" }}>
        {formatNumber(putData?.Ltpch)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(putData?.Ltpchp)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(putData?.Volume)}
      </TableCell>
      <TableCell align="right" sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        {formatNumber(putData?.Oi)}
      </TableCell>
      <TableCell sx={{ fontSize: "0.875rem", fontWeight: "400" }}>
        <Tooltip title={putData?.Symbol || ""}>
          <span>{putData?.Symbol.replace("NSE:", "") || "-"}</span>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
};

/**
 * Reducer for managing optionsData as an Object for efficient updates.
 */
const optionsReducer = (state, action) => {
  switch (action.type) {
    case "SET_OPTIONS":
      // Initialize the state with optionsData
      const newState = {};
      action.payload.forEach((item) => {
        const key = `${formatSymbol(item.Symbol)}-${item.Option_type}`;
        newState[key] = {
          ...item,
          Ltp: item.Ltp,
          Ltpch: item.Ltpch,
          Ltpchp: item.Ltpchp,
          Oi: item.Oi,
          Volume: item.Volume,
        };
      });
      return newState;

    case "UPDATE_LTP":
      // Update multiple LTPs at once
      const updatedState = { ...state };
      action.payload.forEach(({ symbol, ltp, ltpch, ltpchp, oi, vol }) => {
        ["CE", "PE"].forEach((optionType) => {
          const key = `${formatSymbol(symbol)}-${optionType}`;
          if (updatedState[key]) {
            updatedState[key] = {
              ...updatedState[key],
              Ltp: ltp,
              Ltpch: ltpch,
              Ltpchp: ltpchp,
              Oi: oi,
              Volume: vol,
            };
          }
        });
      });
      return updatedState;

    default:
      return state;
  }
};

const OptionChainLive = () => {
  // State Declarations
  const [selectedValue, setSelectedValue] = useState(null);
  const [symbolOptions, setSymbolOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("INDEX"); // 'INDEX' or 'EQ'
  const [strikeCount, setStrikeCount] = useState(10);
  const [expiryDates, setExpiryDates] = useState([]);
  const [selectedExpiry, setSelectedExpiry] = useState("");
  const [symbol, setSymbol] = useState("");
  const [vixData, setVixData] = useState(null);
  const [indexData, setIndexData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [connection, setConnection] = useState(null);

  const [notification, setNotification] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Ref for buffering incoming LTP updates
  const ltpUpdatesBuffer = useRef([]);

  // Reducer for managing optionsData as an Object
  const [optionsData, dispatchOptionsData] = useReducer(optionsReducer, {});

  // Fetch symbol suggestions with debouncing
  const fetchSuggestions = useCallback(async (query) => {
    if (query.length === 0) {
      setSymbolOptions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://api.arasu.online/api/Symbol/UnderSymSuggestions?query=${encodeURIComponent(
          query
        )}&limit=25`
      );
      if (!response.ok) {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
      const data = await response.json();
      setSymbolOptions(data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setError("Failed to fetch symbol suggestions.");
    }
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchSuggestions(inputValue);
    }, 300); // Debounce the API call by 300ms

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue, fetchSuggestions]);

  // Handle expiry date selection
  const handleExpiryChange = (event) => {
    setSelectedExpiry(event.target.value);
    handleFetchOptionsChain(event.target.value);
  };

  // Handle symbol selection
  const handleAutocompleteChange = async (event, newValue) => {
    setSelectedValue(newValue);
    setError("");

    const selectedSymbol = newValue?.underSym || "";
    let symbols = "";

    if (selectedSymbol) {
      if (selectedOption === "INDEX") {
        symbols = `NSE:${selectedSymbol}50-INDEX`;
      } else if (selectedOption === "EQ") {
        symbols = `NSE:${selectedSymbol}-EQ`;
      } else {
        symbols = `NSE:${selectedSymbol}`;
      }
      console.log("Symbol for API call:", symbols);
    } else {
      console.error("No symbol selected.");
      setError("No symbol selected.");
      return;
    }

    setSymbol(symbols);

    try {
      const response = await axios.get(
        "https://api.arasu.online/api/Data/GetOptionsChain",
        {
          params: {
            symbol: symbols,
            strikeCount: strikeCount,
          },
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem('token')}`,
          // },
        }
      );

      console.log("API Response:", response);

      if (response.data.status === 1) {
        const expiryData = response.data.data.data.expiryData.map((item) => ({
          date: item.Date,
          expiry: item.Expiry,
        }));
        setExpiryDates(expiryData);
      } else {
        console.error(
          "API returned an unexpected status:",
          response.data.status
        );
        setError("Failed to fetch expiry dates.");
      }
    } catch (err) {
      console.error("Error fetching options chain:", err);
      setError("An error occurred while fetching options chain data.");
    }
  };

  // Fetch options chain data based on selected expiry
  const handleFetchOptionsChain = async (expiryValue) => {
    const expiry = expiryValue || selectedExpiry;
    if (!expiry) {
      console.log("No expiry date selected.");
      return;
    }
    console.log("Selected Expiry:", expiry);

    setIsLoading(true);
    setError("");
    setVixData(null);
    dispatchOptionsData({ type: "SET_OPTIONS", payload: [] }); // Clear optionsData
    setIndexData(null);

    try {
      const response = await axios.get(
        "https://api.arasu.online/api/Data/GetOptionsChain",
        {
          params: {
            symbol: symbol,
            strikeCount: strikeCount,
            expiry: expiry,
          },
          // headers: {
          //   Authorization: `Bearer ${localStorage.getItem('token')}`,
          // },
        }
      );

      if (response.data.status === 1) {
        setVixData(response.data.data.data.indiavixData);
        setIndexData(response.data.data.data.optionsChainData[0]);

        const optionsChainData =
          response.data.data.data.optionsChainData.slice(1); // Exclude indexData

        // Dispatch to set optionsData
        dispatchOptionsData({ type: "SET_OPTIONS", payload: optionsChainData });
        console.log("Options Chain Data:", optionsChainData);
      } else {
        setError("Failed to fetch options chain data.");
      }
    } catch (err) {
      console.error("Error fetching options chain:", err);
      setError("An error occurred while fetching options chain data.");
    } finally {
      setIsLoading(false);
    }
  };

  // Process options data for table display (memoized)
  const processedOptions = useMemo(() => {
    const processedData = {};

    Object.values(optionsData).forEach((item) => {
      const strikePrice = item.Strike_price;
      const optionType = item.Option_type;

      if (!processedData[strikePrice]) {
        processedData[strikePrice] = {};
      }

      processedData[strikePrice][optionType] = item;
    });

    return processedData;
  }, [optionsData]);

  // Initialize SignalR connection
  useEffect(() => {
    const token = localStorage.getItem("token"); // Assuming token is stored here
    const newConnection = new HubConnectionBuilder()
      .withUrl("https://api.arasu.online/ltpHub", {
        accessTokenFactory: () => token, // Include token if SignalR hub requires authentication
      }) // Ensure this URL matches your backend hub route
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();

    setConnection(newConnection);
  }, []);

  const indexDataRef = useRef(indexData);

  useEffect(() => {
    indexDataRef.current = indexData;
  }, [indexData]);

  const vixDataRef = useRef(vixData);

  useEffect(() => {
    vixDataRef.current = vixData;
  }, [vixData]);

  // Manage SignalR connection and handle incoming messages
  useEffect(() => {
    if (connection) {
      connection
        .start()
        .then(() => {
          console.log("Connected to SignalR hub");

          // Subscribe to ReceiveLtp event
          connection.on("ReceiveLtp", (message) => {
            // console.log("Received LTP data:", message);

            // Assuming message has symbol, ltp, change, changePercentage, openInterest
            const receivedSymbol = formatSymbol(message.symbol);
            const receivedLtp = message.ltp;
            const change = message.change;
            const changePercent = message.changePercentage;
            const openInterest = message.openInterest;
            const volume = message.volumeTradedToday;

            // Update Index Data
            if (
              indexDataRef.current &&
              indexDataRef.current.Description &&
              "NSE:" + indexDataRef.current.Description === receivedSymbol
            ) {
              setIndexData({
                ...indexDataRef.current,
                Ltp: receivedLtp,
                Ltpch: change,
                Ltpchp: changePercent,
              });
            }

            // Update VIX Data
            if (
              vixDataRef.current &&
              vixDataRef.current.Description &&
              "NSE:" + vixDataRef.current.Description === receivedSymbol
            ) {
              setVixData({
                ...vixDataRef.current,
                Ltp: receivedLtp,
                Ltpch: change,
                Ltpchp: changePercent,
              });
            }

            // Push the update to the buffer
            ltpUpdatesBuffer.current.push({
              symbol: receivedSymbol,
              ltp: receivedLtp,
              ltpch: change,
              ltpchp: changePercent,
              oi: openInterest,
              vol: volume,
            });
          });

          // Handle reconnection events
          connection.onreconnecting((error) => {
            console.warn("SignalR reconnecting:", error);
            setNotification({
              open: true,
              message: "Reconnecting to real-time data...",
              severity: "warning",
            });
          });

          connection.onreconnected((connectionId) => {
            console.log("SignalR reconnected. Connection ID:", connectionId);
            setNotification({
              open: true,
              message: "Reconnected to real-time data.",
              severity: "success",
            });
          });

          connection.onclose((error) => {
            console.error("SignalR connection closed:", error);
            setNotification({
              open: true,
              message: "Disconnected from real-time data.",
              severity: "error",
            });
          });
        })
        .catch((err) => {
          console.error("Failed to connect to SignalR hub:", err);
          setNotification({
            open: true,
            message: "Failed to connect to real-time data.",
            severity: "error",
          });
        });

      // Cleanup on unmount
      return () => {
        connection
          .stop()
          .then(() => console.log("Disconnected from SignalR hub"))
          .catch((err) =>
            console.error("Error disconnecting from SignalR hub:", err)
          );
      };
    }
  }, [connection]);

  // Batch process buffered LTP updates
  useEffect(() => {
    const interval = setInterval(() => {
      if (ltpUpdatesBuffer.current.length > 0) {
        // Create a copy of the buffer and clear it
        const updatesToProcess = [...ltpUpdatesBuffer.current];
        ltpUpdatesBuffer.current = [];

        // Dispatch to update optionsData
        dispatchOptionsData({ type: "UPDATE_LTP", payload: updatesToProcess });
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  // Handle Snackbar close
  const handleCloseNotification = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setNotification((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <HomePage />
      <Box sx={{ p: 2, m: 2, backgroundColor: "#f5f5f5", borderRadius: 2 }}>
        <Box sx={{ width: "100%" }}>
          {/* Display Error Messages */}
          {error && (
            <Box sx={{ mb: 2 }}>
              <Alert severity="error" onClose={() => setError("")}>
                {error}
              </Alert>
            </Box>
          )}

          {/* Notification Snackbar */}
          <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={handleCloseNotification}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleCloseNotification}
              severity={notification.severity}
              sx={{ width: "100%" }}
            >
              {notification.message}
            </Alert>
          </Snackbar>

          {/* Input Controls */}
          <Grid
            container
            spacing={2}
            alignItems="center"
            wrap="wrap"
            sx={{
              overflowX: "auto",
            }}
          >
            {/* Autocomplete Component */}
            <Grid item xs={12} sm={6} md={2.6}>
              <Autocomplete
                fullWidth
                options={symbolOptions}
                getOptionLabel={(option) => option.underSym || ""}
                value={selectedValue}
                onChange={handleAutocompleteChange}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                  setError(""); // Clear error when input changes
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Symbol"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            {/* Radio Buttons */}
            <Grid item xs={12} sm={6} md={1.5}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  name="row-radio-buttons-group"
                  value={selectedOption}
                  onChange={(event) => setSelectedOption(event.target.value)}
                >
                  <FormControlLabel
                    value="INDEX"
                    control={<Radio color="primary" />}
                    label="INDEX"
                  />
                  <FormControlLabel
                    value="EQ"
                    control={<Radio color="primary" />}
                    label="EQ"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Strike Count Input */}
            <Grid item xs={12} sm={6} md={1}>
              <TextField
                label="Strike Count"
                type="number"
                value={strikeCount}
                onChange={(e) => setStrikeCount(Number(e.target.value))}
                fullWidth
                InputProps={{ inputProps: { min: 1 } }}
              />
            </Grid>
            {/* Expiry Dates */}
            {expiryDates.length > 0 && (
              <Grid item xs={12} sm={6} md={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="expiry-select-label">Expiry Date</InputLabel>
                  <Select
                    labelId="expiry-select-label"
                    value={selectedExpiry}
                    label="Expiry Date"
                    onChange={handleExpiryChange}
                  >
                    {expiryDates.map((expiry) => (
                      <MenuItem key={expiry.expiry} value={expiry.expiry}>
                        {expiry.date}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>

          {/* Loading Indicator */}
          {isLoading && (
            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <CircularProgress />
            </Box>
          )}

          {/* VIX and Index Data as Cards */}
          {(vixData || indexData) && (
            <Box sx={{ mb: 2, width: "75%" }}>
              <Grid container spacing={1}>
                {/* Index Data Card */}
                {indexData && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      sx={{
                        height: "50px",
                        marginTop: "10px",
                        backgroundColor: "#fff3e0",
                        "&:hover": {
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={1}>
                          <Grid item xs={12} sm={6} md={12}>
                            <Typography variant="body2" sx={{fontWeight:"bold"}}>
                              {indexData.Description.replace("-INDEX", " :") +
                                "\u00A0\u00A0" +
                                formatNumber(indexData.Ltp) +
                                "\u00A0\u00A0(" +
                                formatNumber(indexData.Ltpch) +
                                ")\u00A0\u00A0(" +
                                formatNumber(indexData.Ltpchp) +
                                "%)"}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}

                {/* VIX Data Card */}
                {vixData && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Card
                      sx={{
                        height: "50px",
                        marginTop: "10px",
                        backgroundColor: "#e3f2fd",
                        "&:hover": {
                          boxShadow: 6,
                        },
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={1}>
                          {vixData.map((item, idx) => (
                            <React.Fragment key={idx}>
                              <Grid item xs={12} sm={6} md={12}>
                                <Typography variant="body2"  sx={{fontWeight:"bold"}}>
                                  {item.Description.replace("-INDEX", " :") +
                                    "\u00A0\u00A0" +
                                    formatNumber(item.Ltp) +
                                    "\u00A0\u00A0(" +
                                    formatNumber(item.Ltpch) +
                                    ")\u00A0\u00A0(" +
                                    formatNumber(item.Ltpchp) +
                                    "%)"}
                                </Typography>
                              </Grid>
                            </React.Fragment>
                          ))}
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Box>
          )}

          {/* Options Chain Data */}
          {Object.keys(processedOptions).length > 0 && (
            <TableContainer
              component={Paper}
              sx={{ maxHeight: 720, overflowX: "auto" }}
            >
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "primary.dark",
                        textAlign: "center",
                      }}
                    >
                      Calls
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "primary.main",
                        textAlign: "center",
                      }}
                    >
                      Strike Price
                    </TableCell>
                    <TableCell
                      align="center"
                      colSpan={6}
                      sx={{
                        fontWeight: "bold",
                        color: "#fff",
                        backgroundColor: "primary.dark",
                        textAlign: "center",
                      }}
                    >
                      Puts
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    {/* Call Headers */}
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Symbol
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      OI
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Volume
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Change%
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Change
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      LTP
                    </TableCell>

                    {/* Strike Price */}
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "secondary.main",
                      }}
                    >
                      Strike Price
                    </TableCell>

                    {/* Put Headers */}
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      LTP
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Change
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Change%
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Volume
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      OI
                    </TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "bold",
                        color: "white",
                        backgroundColor: "primary.main",
                      }}
                    >
                      Symbol
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.entries(processedOptions).map(
                    ([strikePrice, data], index) => (
                      <OptionRow
                        key={strikePrice}
                        strikePrice={strikePrice}
                        callData={data["CE"]}
                        putData={data["PE"]}
                        index={index}
                      />
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>

      {/* Notification Snackbar at Bottom Center */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OptionChainLive;
